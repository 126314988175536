import { Component, Input } from '@angular/core';
import { Commaster } from './models/commaster';
import { DataService } from './data.service';
import { environment } from 'src/environments/environment';
import { ModulpageService } from './modulpage/modulpage.service';

@Component({
  selector: 'my-nav',
  providers: [ModulpageService],
  templateUrl: './nav.component.html',
})

export class NavComponent extends Commaster {
  @Input() isActive: string;

  showNavBar = '';

  startpath = ''

  constructor(public dataService: DataService, private modulpageService: ModulpageService) {
    super(dataService);
    // console.log("NavState " + this.showNavBar)
    this.dataService.registerNavComponent(this)
    this.showNavBar = this.dataService.getNavState()
    this.startpath = environment.startpath
  }

  public getNavigationMode() {
    return this.dataService.getNavigationMode()
  }

  public setNavigationMode(nav) {
    return this.dataService.setNavigationMode(nav)
  }

  public navChanged() {
    // console.log("NavState: navChanged")
    this.showNavBar = this.dataService.getNavState()
  }

  public showMainNav() {
    this.showNavBar = "main"
    this.dataService.setNavState(this.showNavBar)
    this.context = ('')
    this.storeContext('')

  }

  loadModule(nav) {
    // console.log("LoadModule for " + nav)
    if (nav === null || nav === undefined || nav === '') {
      this.dataService.getNavigationMode().then((nav) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })
    } else {
      this.dataService.setNavigationMode(nav).then((_) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })

    }

  }


  reloadData() {
    // console.log("NavState: reloadData")
    localStorage.setItem('reload', 'yes');
    this.showNavBar = this.dataService.getNavState()
  }

  setModulbase(modus: string): void {
    // console.log("NavState: setModulbase " + modus)
    this.modulemode = modus
    this.storeModulemode(modus);
  }
}
