import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { UKService } from './uk.service';
import { GlobalEventsManager } from '../services/GlobalEventsManager';
import { Commaster } from '../models/commaster';
import { Thema } from '../models/thema';
import { ModulpageService } from '../modulpage/modulpage.service';
import { Modul } from '../models/modul';
import { Metadata } from '../models/metadata';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [UKService],
  templateUrl: '../html/uk.html',
  styleUrls: ['uk.component.css']
})

export class UKComponent extends Commaster implements OnInit {

  title = 'Herzlich Willkommen beim ComBook für Unternehmerkunden';
  subtitle = 'Ihre Unterstützung bei der Erstellung professioneller Kundenpräsentationen.';
  ukpages: Mainpage[];
  selectedMainpage: Mainpage;

  constructor(private ukService: UKService, public dataService: DataService) {
    super(dataService);

    // console.log('UKComponent constructor ' + this.context + '-' + this.modulemode);

    this.storeContext('uk');
    this.storeModulemode(this.UK_MODULE);
    //this.globalEventsManager.showNavBar('uk');
    this.dataService.setNavState(this.context);


    // console.log('/UKComponent constructor ' + this.context + '-' + this.modulemode);
  }

  setFile(modul: Modul) {
    // console.log('THEMA');

    const temp: Modul[] = [];
    // Store the selection to the metadata
    localStorage.setItem('metadata', '');
    const metadata: Metadata = new Metadata; // .getMetadata()
    temp.push(modul);
    metadata.module = temp;
    metadata.storeMetadata(this.context);

    this.storeCurrentPresentationMode('thema');
    // this.storeCurrentPresentation(modul.link)
  }

  getUKpages(): void {
    this.ukService.getUKpages().then(mainpages => this.ukpages = mainpages);

  }

  ngOnInit(): void {
    // console.log('UKComponent ngOnInit ' + this.context + '-' + this.modulemode);

    localStorage.setItem('context', 'uk');
    localStorage.setItem('moduleuk_module', '');
    this.getUKpages();

    // console.log(this.ukService.getUKpages());

    // console.log('UKComponent ngOnInit ' + this.context + '-' + this.modulemode);

  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }

  reloadData() {
    localStorage.setItem('reload', 'yes');
  }
}
