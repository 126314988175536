import { NgModule, Component, OnInit, Input } from '@angular/core';
import { Agendapage } from '../models/agendapage';
import { AgendaService } from '../agenda/agenda.service';
import { ModulpageService } from '../modulpage/modulpage.service';
import { Modul } from '../models/modul';
import { GlobalEventsManager } from '../services/GlobalEventsManager';
import { Location } from '@angular/common';
import { Commaster } from '../models/commaster';
import { Metadata } from '../models/metadata';
import { enableDebugTools } from '@angular/platform-browser/src/browser/tools/tools';
import { last } from '@angular/router/src/utils/collection';
import { DataService } from '../data.service';
import { Mainpage } from '../models/mainpage';
import { Router } from '@angular/router';


@Component({
  selector: 'my-app',
  providers: [AgendaService, ModulpageService],
  templateUrl: 'agenda.html',
  styleUrls: ['agenda.component.css']
})



export class AgendaComponent extends Commaster implements OnInit {

  tempArray: Modul[] = [];
  selectionArray: Modul[] = [];
  agendaModule: Modul[];
  backlink = '';
  sprache = 'de'
  nextAction = '/textblock';

  modulauswahlzurueck = ''
  weiter = ''
  zurueck = ''
  vorsicht = ''

  previous_page = ''

  title = 'Agenda';
  subtitle = 'Hier kann die Agenda bearbeitet werden.';

  title_en = 'Agenda';
  subtitle_en = 'If necessary, change the order of agenda.';

  constructor(public dataService: DataService, private route: Router, private modulpageService: ModulpageService) {
    super(dataService);
    // console.log('AgendaComponent constructor ' + this.context + '-' + this.modulemode);
  }

  ngOnInit(): void {

    // console.log('ngOnInit constructor ' + this.context + '-' + this.modulemode);

    this.dataService.getPfad().then((pfad) => {
      this.previous_page = pfad
    })

    this.dataService.getLanguage().then((lang) => {
      if (lang !== null && lang !== undefined && lang !== '') {
        this.sprache = lang
      } else {
        this.sprache = 'de'
      }

      if (this.sprache === 'de') {
        this.modulauswahlzurueck = "Modulauswahl zurücksetzen"
        this.weiter = 'Weiter'
        this.zurueck = 'Zurück'
        this.vorsicht = '* Vorsicht: Die Sortierung wird verworfen, wenn Sie zurück zur Modulauswahl gehen.'
      } else {
        this.modulauswahlzurueck = "Reset selection"
        this.weiter = 'Next'
        this.zurueck = 'Back'
        this.vorsicht = '* Please Note: Order will be reset when you go back.'
      }

    })


    if (this.route.url === '/wm_module_en') {
      this.sprache = 'en'
    }

    const agendaModel = this.getAgendaModel();

    for (const entry of agendaModel) {
      // console.log(">" + entry.name);
    }

    this.getAllModule().then((res) => {

      this.context = this.getContext();
      this.modulemode = this.getModulemode();

      //this.globalEventsManager.showNavBar(this.context);
      //this.dataService.setNavState(this.context);
      if ((this.modulemode === this.IK_MODULE_DE) || (this.modulemode === this.IK_MODULE_EN)) {
        this.backlink = '/' + this.modulemode;
        this.nextAction = '/kunde';
      }
      if (this.modulemode === this.UK_MODULE) {
        this.nextAction = '/kunde';
      }
      // console.log(this.tempArray)
      // console.log(res)

    }).then((res) => {
      // console.log("go ahead")
    });




    // console.log('/ngOnInit constructor ' + this.context + '-' + this.modulemode);
    // console.log(this.getContext() + ' ' + this.getLanguage());
  }

  storeAgenda(modulxxx: Modul[]) {

    this.storeAgendaModel(modulxxx);

    // Store the selection to the metadata
    const metadata: Metadata = Metadata.getMetadata(this.getContext());
    metadata.module = modulxxx; //this.tempArray;

    // console.log("storeAgenda  " + this.getContext())
    // console.log(metadata)

    metadata.storeMetadata(this.getContext());

  }


  agendaTopicLoeschen(modul: Modul) {
    // console.log('Löschen: ' + modul.name);
    this.tempArray = this.removeModuleFromModuleTree(modul, this.tempArray);
    this.storeAgenda(this.tempArray);
  }

  moveUp(modul: Modul, parent: Modul, module: Modul[]) {
    // console.log("UP " + modul.name)
    this.tempArray = this.navigateTo(modul, parent, module, 'up');
    // console.log(this.tempArray)

    this.storeAgenda(this.tempArray);
    //this.storeLastAgendaModel(this.tempArray);
  }

  moveDown(modul: Modul, parent: Modul, module: Modul[]) {
    // console.log("DOWN " + modul.name)
    this.tempArray = this.navigateTo(modul, parent, module, 'down');
    // console.log(this.tempArray)
    this.storeAgenda(this.tempArray);
    //this.storeLastAgendaModel(this.tempArray);
  }

  navigateTo(modul: Modul, parent: Modul, module: Modul[], dir: string): Modul[] {

    if (modul.level === 1) {

      const subtree = module;
      const ind = subtree.indexOf(modul);
      const temp = subtree[ind];

      if (subtree.length > 1) {
        // console.log("move me " + dir + " " + subtree.length)
        // console.log(subtree.indexOf(modul))


        if (dir === 'down' && subtree.length > ind + 1) {

          subtree[ind] = subtree[ind + 1];
          subtree[ind + 1] = temp;
          // console.log("switched")
        }
        if (dir === 'up' && ind > 0) {

          subtree[ind] = subtree[ind - 1];
          subtree[ind - 1] = temp;
          // console.log("switched")
        }
      }
    }

    if (modul.level > 1) {

      const subtree = parent.submodule;
      const ind = subtree.indexOf(modul);
      const temp = subtree[ind];

      if (subtree.length > 1) {
        // console.log("move me " + dir + " " + subtree.length)
        // console.log(subtree.indexOf(modul))


        if (dir === 'down' && subtree.length > ind + 1) {

          subtree[ind] = subtree[ind + 1];
          subtree[ind + 1] = temp;
          // console.log("switched")
        }
        if (dir === 'up' && ind > 0) {

          subtree[ind] = subtree[ind - 1];
          subtree[ind - 1] = temp;
          // console.log("switched")
        }
      }
    }

    return module;
  }

  displayName(modul: Modul): string {
    // console.log(modul.displayname);
    if (modul.displayname != undefined && modul.displayname !== '') {
      return modul.displayname;
    }
    return modul.name;
  }

  rebuildTree(modulx: Modul[]): void {

    // console.log("rebuildTree")
    // console.log(modulx)

    const result: Modul[] = [];

    const tempModuleTree: Modul[] = [];

    let parent_orig: Modul = new Modul;
    let grandparent_orig: Modul = new Modul;
    let masterparent_orig: Modul = new Modul;
    let parent_result: Modul = new Modul;
    let grandparent_result: Modul = new Modul;
    let masterparent_result: Modul = new Modul;

    //const lastAgendaModel = this.getLastAgendaModel();
    let agendaModel;

    /*
    if (lastAgendaModel.length === 0) {
      //agendaModel = this.getSelectionModel();
      agendaModel = this.getSelectedAsList(modulx)
    } else {
      agendaModel = lastAgendaModel;
      // console.log('*** Taking lastAgenda ***');
    }
    */
    agendaModel = this.getSelectedAsList(modulx)
    // console.log(agendaModel)

    //const agendaModel = this.getSelectionModel(); //   this.getAgendaModel()
    //this.fullDumpSelection(agendaModel);

    //this.fullDumpSelection(this.getLastAgendaModel());
    /*
    var agendaModel: Modul[] = [];
    for (const entry of this.getAgendaModel()) {
      // console.log(">" + entry.name);
      //if (entry.submodule.length===0) {
        agendaModel.push(entry);
      //}
    }
    */
    //this.dumpSelection(this.getLastAgendaModel());

    for (const entry of agendaModel) {
      // console.log(entry);
      if (entry.level === 3) {
        parent_result = this.getSubModuleById(entry.parent, tempModuleTree);
        // console.log('> ' + parent_result);
        if (parent_result == null) {
          parent_orig = this.getSubModuleById(entry.parent, modulx);
          // console.log('> ');
          if (parent_orig != null) {
            parent_result = new Modul;
            parent_result.id = parent_orig.id;
            parent_result.level = 3;
            parent_result.link = parent_orig.link;
            parent_result.displayname = parent_orig.displayname;
            parent_result.parent = parent_orig.parent;
            parent_result.mandatoryinfo = parent_orig.mandatoryinfo;
            parent_result.name = parent_orig.name;
            parent_result.submodule = [];
          }
        }

        grandparent_result = this.getSubModuleById(parent_result.parent, tempModuleTree);
        // console.log('grandpa ' + grandparent_result);
        if (grandparent_result == null) {
          grandparent_orig = this.getSubModuleById(parent_orig.parent, modulx);
          grandparent_result = new Modul;
          grandparent_result.id = grandparent_orig.id;
          grandparent_result.level = 2;
          grandparent_result.parent = grandparent_orig.parent;
          grandparent_result.mandatoryinfo = grandparent_orig.mandatoryinfo;
          grandparent_result.displayname = grandparent_orig.displayname;
          grandparent_result.link = grandparent_orig.link;
          grandparent_result.name = grandparent_orig.name;
          grandparent_result.submodule = [];
          // console.log('created new grandpa ' + grandparent_result.parent);
        }

        masterparent_result = this.getSubModuleById(grandparent_result.parent, tempModuleTree);
        // console.log('master ' + grandparent_result.parent);
        if (masterparent_result == null) {
          // console.log("2creating new ")
          masterparent_orig = this.getSubModuleById(grandparent_orig.parent, modulx);
          masterparent_result = new Modul;
          masterparent_result.id = masterparent_orig.id;
          masterparent_result.level = 1;
          masterparent_result.link = masterparent_orig.link;
          masterparent_result.mandatoryinfo = masterparent_orig.mandatoryinfo;
          masterparent_result.displayname = masterparent_orig.displayname;
          masterparent_result.name = masterparent_orig.name;
          masterparent_result.submodule = [];
        }

        /*
        // console.log("mp: " + masterparent_result.id)
        // console.log("gp: " + grandparent_result.id)
        // console.log("p: " + parent_result.id)
        // console.log("o: " + entry.id)
        */


        if (parent_result != null && parent_result.submodule.indexOf(entry) < 0) {
          entry.level = 4;
          parent_result.submodule.push(entry);
        }

        if (grandparent_result != null && grandparent_result.submodule.indexOf(parent_result) < 0) {
          parent_result.level = 3;
          grandparent_result.submodule.push(parent_result);
        }

        if (masterparent_result != null && masterparent_result.submodule.indexOf(grandparent_result) < 0) {
          grandparent_result.level = 2;
          masterparent_result.submodule.push(grandparent_result);
        }

        if (tempModuleTree.indexOf(masterparent_result) < 0) {
          masterparent_result.level = 1;
          // console.log("add " + masterparent_result.id);
          tempModuleTree.push(masterparent_result);
        }

      }

      if (entry.level === 2) {
        parent_result = this.getSubModuleById(entry.parent, tempModuleTree);
        if (parent_result == null) {
          // console.log("1creating new " + entry.parent)
          parent_orig = this.getSubModuleById(entry.parent, modulx);
          if (parent_orig != null) {
            parent_result = new Modul;
            parent_result.id = parent_orig.id;
            parent_result.level = 2;
            parent_result.link = parent_orig.link;
            parent_result.displayname = parent_orig.displayname;
            parent_result.parent = parent_orig.parent;
            parent_result.mandatoryinfo = parent_orig.mandatoryinfo;
            parent_result.name = parent_orig.name;
            parent_result.submodule = [];
          }
        }
        grandparent_result = this.getSubModuleById(parent_result.parent, tempModuleTree);
        if (grandparent_result == null) {
          // console.log("2creating new " + parent_result.parent)
          grandparent_orig = this.getSubModuleById(parent_orig.parent, modulx);
          grandparent_result = new Modul;
          grandparent_result.id = grandparent_orig.id;
          grandparent_result.level = 1;
          grandparent_result.link = grandparent_orig.link;
          grandparent_result.displayname = grandparent_orig.displayname;
          grandparent_result.mandatoryinfo = grandparent_orig.mandatoryinfo;
          grandparent_result.name = grandparent_orig.name;
          grandparent_result.submodule = [];
        }

        /*
        // console.log("gp: " + grandparent_result.id)
        // console.log("p: " + parent_result.id)
        // console.log("o: " + entry.id)
        */
        if (parent_result != null && parent_result.submodule.indexOf(entry) < 0) {
          entry.level = 3;
          parent_result.submodule.push(entry);
        }

        if (grandparent_result != null && grandparent_result.submodule.indexOf(parent_result) < 0) {
          parent_result.level = 2;
          grandparent_result.submodule.push(parent_result);
        }

        if (tempModuleTree.indexOf(grandparent_result) < 0) {
          grandparent_result.level = 1;
          tempModuleTree.push(grandparent_result);
        }


      }


      if (entry.level === 1) {
        // console.log("1")
        parent_result = this.getModuleById(entry.parent, tempModuleTree);
        // console.log("2")

        if (parent_result == null) {
          // console.log("3")
          parent_orig = this.getModuleById(entry.parent, modulx);
          if (parent_orig != null) {
            // console.log("4")
            parent_result = new Modul;
            parent_result.id = parent_orig.id;
            parent_result.level = 1;
            parent_result.link = parent_orig.link;
            parent_result.displayname = parent_orig.displayname;
            parent_result.name = parent_orig.name;
            parent_result.mandatoryinfo = parent_orig.mandatoryinfo;
            parent_result.submodule = [];
          }
        }

        if (parent_result.submodule.indexOf(entry) < 0) {
          entry.level = 2;
          parent_result.submodule.push(entry);
        }

        if (tempModuleTree.indexOf(parent_result) < 0) {
          tempModuleTree.push(parent_result);
        }
      }

    }

    this.tempArray = tempModuleTree;
    // console.log("tempArray")
    // console.log(this.tempArray)

    this.storeAgenda(tempModuleTree);
    this.storeAgendaModel(tempModuleTree);

  }


  getAllModule(): Promise<any> {

    return this.dataService.getPfad().then((pfad) => {
      return this.dataService.getModules(pfad).then((modules) => {
        console.log("getAllModules@agenda for " + pfad)
        console.log(modules)
        return this.rebuildTree(modules);
      })

    })

  }

  loadModule(nav) {
    // console.log("LoadModule for " + nav)
    if (nav === null || nav === undefined || nav === '') {
      this.dataService.getNavigationMode().then((nav) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })
    } else {
      this.dataService.setNavigationMode(nav).then((_) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })

    }

  }

}



