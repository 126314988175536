import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NavComponent } from './nav.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CloseComponent } from './close/close.component';
import { SharedService } from './services/shared.service';
import { MainpageRoutingModule } from './mainpage/mainpage-routing.module';
import { MainpageModule } from './mainpage/mainpage.module';
import { MainpageComponent } from './mainpage/mainpage/mainpage.component';
import { ModulpageRoutingModule } from './modulpage/modulpage-routing.module';
import { ModulpageModule } from './modulpage/modulpage.module';
import { FavoritenpageRoutingModule } from './favoritenpage/favoritenpage-routing.module';
import { FavoritenpageModule } from './favoritenpage/favoritenpage.module';
import { WMComponent } from './wmpage/wm.component';
import { UKComponent } from './unternehmerkunden/uk.component';
import { IKComponent } from './internationalekunden/ik.component';
import { AdminpageModule } from './adminpage/adminpage.module';
import { AgendaComponent } from './agenda/agenda.component';
import { TextblockComponent } from './textblock/textblock.component';
import { KundeComponent } from './kunde/kunde.component';
import { EnglishComponent } from './englishpage/english.component';
import { EditorpageComponent } from './editorpage/editorpage/editorpage.component';
import { ThemaDetailComponent } from './themenpage/thema-detail.component';
import { ThemenComponent } from './themenpage/themen.component';
import { NavigationbuilderRoutingModule } from './navigationbuilder/navigationbuilder-routing.module';
import { NavigationbuilderModule } from './navigationbuilder/navigationbuilder.module';
import { GlobalEventsManager } from './services/GlobalEventsManager';
import { HttpModule } from '@angular/http';
import { DatePipe } from '@angular/common';
import { EscapeHtmlPipe } from './models/keep-html.pipe';
import { WindowRef } from './dialog/dialog.service';
import { SliderComponent } from './slider/slider.component';
import { Storage } from '@ionic/storage';
import { ModulpageService } from './modulpage/modulpage.service';
import { ThemaService } from './themenpage/themen.service';

export function provideStorage() {
  return new Storage({name:"__mydb"});
 }

@NgModule({
   imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    MainpageModule,
    AdminpageModule,
    MainpageRoutingModule,
    ModulpageModule,
    ModulpageRoutingModule,
    NavigationbuilderModule,
    NavigationbuilderRoutingModule,
    FavoritenpageModule,
    FavoritenpageRoutingModule,
    AppRoutingModule
  ],
  declarations: [
    MainpageComponent,
    ImpressumComponent,
    FeedbackComponent,
    CloseComponent,
    AppComponent,
    NavComponent,
    WMComponent,
    UKComponent,
    IKComponent,
    KundeComponent,
    TextblockComponent,
    AgendaComponent,
    EnglishComponent,
    EditorpageComponent,
    ThemaDetailComponent,
    ThemenComponent,
    EscapeHtmlPipe,
    SliderComponent
  ],

  providers: [
      SharedService,
      ThemaService,
      DatePipe,
      { provide: Storage, useFactory: provideStorage },
      GlobalEventsManager,
      WindowRef
  ],
  bootstrap: [ AppComponent, NavComponent ]
})

export class AppModule {
}

