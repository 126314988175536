export class Thema {
  id: number;
  name: string;
  description?: string;
  link: string;
  linkOnly?: boolean;
  templateid: number;
  subthemen: Thema[];
  vertriebsbox?: string;
  modaldialog?: string;
}