import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Modul } from './models/modul';
import { NavComponent } from './nav.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private storage: Storage) {
    storage = new Storage({ name: "__mydb" });
  }

  language: string
  auswahl: Modul[]

  navComponent: NavComponent

  navState: string = "wm"

  public init() {

    this.storage.create();
  }

  public getFullState() {
    // console.log("getFullState")
    return this.storage.get("LANGUAGE").then((lang) => {
      // console.log(lang)
      this.language = lang

      return this.storage.get("AUSWAHL").then((auswahl) => {
        this.auswahl = auswahl
      })
    })
  }

  public getNavigationMode() {
    return this.storage.get("navigation")
  }

  public setNavigationMode(nav) {
    return this.storage.set("navigation", nav)
  }

  public async resetAuswahl() {
    // console.log("-------------------------------")
    // console.log("<<<- Auswahl deleted---")
    // console.log("-------------------------------")
    // TODO 2024-04-03
    return this.storage.remove("")
  }

  public async getModules(name: string) {
    // console.log("-------------------------------")
    // console.log("<<<-Module " + name + " read---")
    // console.log("-------------------------------")
    const returner = this.storage.get(name)
    // console.log(returner)
    return returner
  }

  public setModules(name, toSave) {
    return this.storage.set(name, toSave).then((_) => {
      // console.log("-------------------------------")
      console.log(">>>-Modules " + name + " written---")
      // console.log("-------------------------------")

      // console.log(toSave)
    });

  }

  public async getPfad() {
    const returner = this.storage.get("pfad")
    return returner
  }

  public setPfad(toSave) {
    return this.storage.set("pfad", toSave).then((_) => {
    });

  }

  public registerNavComponent(navComponent: NavComponent) {
    this.navComponent = navComponent
  }

  public getNavState() {
    // console.log("getNavState " + this.navState)
    return this.navState
  }

  public setNavState(navState: string) {
    // console.log("setNavState " + navState)
    if (navState != "") {
      this.navState = navState
      this.navComponent.navChanged()
    }
  }

  public getLanguage() {
    return this.storage.get('language')
  }

  public async setLanguage(language: string) {

    await this.storage.set('language', language).then((_) => {
      // console.log("setLanguage to " + language)
    })
  }
}
