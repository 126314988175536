import { Injectable } from '@angular/core';
import { Commaster } from '../models/commaster';
import { Modul } from '../models/modul';
import { Thema } from '../models/thema';
import { Mainpage } from '../models/mainpage';
import { DataService } from '../data.service';

@Injectable()
export class ModulpageService extends Commaster {

  constructor(public dataService: DataService) {
    super(dataService);
  }

  getModule(modulemode: Mainpage): Modul[] {

    // console.log('MPS: getModule ' + modulemode.ctx);
    // let temp: Modul[] = [];

    // console.log('Load Modules for ' + modulemode.ctx + " " + modulemode.lang)

    if (modulemode.ctx == "EnglishBook" && modulemode.lang == "en") {
      this.load(this.ENGLISH_MODULE);
      return this.english_assets;
    }

    if (this.pfad == 'pb_module') {
      console.log("load modules over HTTP")
      this.load('pb_module');
      return this.pb_de_assets;
    }

    if (modulemode.lang === 'de') {

      // console.log('Loading for DE')

      if (modulemode.ctx == 'WMbook') {
        // console.log('Load WMbook')
        this.load('wm_module');
        this.storeLanguage('de')
        return this.wm_assets;
      }

      if (modulemode.ctx === 'UKbook') {
        this.load('uk_module');
        return this.uk_assets;
      }

      if (modulemode.ctx === 'IKbook') {
        this.load('ik_module');
        return this.ik_assets;
      }




    } else {

      // console.log('Loading for EN')

      if (modulemode.ctx == 'WMbook') {
        // console.log('Load WMbook')
        this.load('wm_module_en');
        this.storeLanguage('en')
        return this.wm_assets;
      }

      if (modulemode.ctx == 'IKbook') {
        this.load('ik_en_module');
        return this.ik_en_assets;
      }

      if (modulemode.ctx == 'EnglishBook') {
        this.load(this.ENGLISH_MODULE);
        return this.english_assets;
      }


    }


  }

  getModuleFromJSON(what: string): Modul[] {

    // console.log("getModuleFromJSON")
    // console.log(what)

    if (what == null || what == undefined || what === '') {

      // console.log('MPS: getModuleFromJSON for null, retrieve from storage');

      this.dataService.getNavigationMode().then((what) => {
        // console.log('MPS: getModuleFromJSON for ' + what);
        return this.getModulesIntern(what)
      })

    } else {
      // console.log('MPS: getModuleFromJSON for ' + what);
      return this.getModulesIntern(what)
    }

  }


  getModulesIntern(what): Modul[] {

    if (what == 'module') {
      // console.log('Load Module')
      this.load('wm_module');
      this.storeLanguage('de')
      return this.wm_assets;
    }

    if (what == 'module_en') {
      // console.log('Load Module *EN*')
      this.load('wm_module_en');
      this.storeLanguage('en')
      return this.wm_assets;
    }

    if (what == 'uk_module') {
      // console.log('Load UK Module')
      this.load('uk_module');
      this.storeLanguage('de')
      return this.uk_assets;
    }

    if (what == 'ik_module') {
      // console.log('Load Module IK')
      this.load('ik_module');
      this.storeLanguage('de')
      return this.ik_assets;
    }

    if (what == 'ik_module_en') {
      // console.log('Load Module IK *EN*')
      this.load('ik_module_en');
      this.storeLanguage('en')
      return this.ik_assets;
    }

  }



  getThemen(context: string, subcontext: string): Thema[] {

    // console.log('getThemen>' + context);
    if (context === 'wm') {
      if (subcontext === 'uk_themen_en') {
        this.load('wm_en_module');
        return this.wm_en_assets;
      } else {
        this.load('wm_themen');
        return this.wm_themen_assets;
      }
    }

    if (context === 'uk') {

      if (subcontext === 'uk_themen_en') {
        this.load('uk_themen_en');
        return this.uk_themen_en_assets;
      }
      if (subcontext === 'uk_themen') {
        this.load('uk_themen');
        return this.uk_themen_assets;
      }


    }

  }



}
