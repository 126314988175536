import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalEventsManager} from "../services/GlobalEventsManager";

import { ModulpageComponent } from './modulpage/modulpage.component';
import { ModulpageRoutingModule } from './modulpage-routing.module';



@NgModule({
  imports: [
    CommonModule,
    ModulpageRoutingModule
  ],
  providers: [GlobalEventsManager],
  declarations: [ModulpageComponent]
})

export class ModulpageModule {

  constructor(private globalEventsManager: GlobalEventsManager) {
  }
}
