import { Component, OnInit } from '@angular/core';
import { Thema } from '../models/thema';
import { ModulpageService } from '../modulpage/modulpage.service';
import { Commaster } from '../models/commaster';
import { Modul } from '../models/modul';
import { Metadata } from '../models/metadata';
import { WindowRef } from '../dialog/dialog.service';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [ModulpageService],
  templateUrl: 'themen.html',
  styleUrls: ['themen.component.css']

})


export class ThemenComponent extends Commaster implements OnInit {

  title = 'Fertige Präsentationen für häufige Gesprächsthemen';
  subtitle = 'Präsentationen zu aktuellen Vertriebsthemen';
  modaldialog = '';

  themen: Thema[];
  selectedThema: Thema;

  modal: number = -1;

  //vertriebsbox = '<aside class="w350"><p><span style="font-size:12px"><strong>WM-Akquisitionsinitiative</strong></span></p><br/><p><center><img src="https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/Vertriebsbox/akqui_160.png"></center></p><p>&nbsp;</p><p><br/><img src="./assets/images/pfeil_gelb.png"><b><a href="http://comnet.intranet.commerzbank.com/comnet/media/zentrale_1/pcwm/1bilder_1/vertriebsmanagement_1/wm_akquisitionsintiative/WM_Akquistionsinitiative_Praesentation_TeamMeeting_20180301.pdf" target="_blank">Basispräsentation</a></b><br><img src="./assets/images/pfeil_gelb.png"><b><a href="http://comnet.intranet.commerzbank.com/comnet/de/wealthmanagement/vertriebsmanahmenundverkaufsfrderung_1/wm_akquisitionsinitiative_uk_und_fk/wm_akquisitionsinitiative_1.jsp" target="_blank">Zum Comnet-Auftritt</a></b></p></aside>';

  h1Fett = -1;
  nativeWindow: any;

  constructor(private winRef: WindowRef, private themaService: ModulpageService, public dataService: DataService) {
    super(dataService);

    //this.modaldialog = this.httpGetHTML("./assets/wettbewerber.htm");
    this.nativeWindow = winRef.getNativeWindow();

    this.dataService.setLanguage('de')
    this.dataService.getLanguage().then((_) => {
      // console.log("Setlanguage to " + _)
    })

  }


  getThemen(context: string): void {

    this.themen = this.themaService.getThemen(context, 'uk_themen');

    if (context === 'uk') {
      //this.vertriebsbox = '<span class="w350"><aside class="w350"><p><span style="font-size:12px"><strong>Last Call Top-Kondition 1,75 % beim CBU.</strong></span></p><p>&nbsp;</p><p><span style="font-size:10px">Die Alternative kann auch <b><a href="https://www.commerzbank.de/portal/de/unternehmerkunden/produkte/finanzierung/leasing/leasing.html" target="_blank">Leasing </a></b> sein. <br>Weitere UK-Lösungen:</span></p><p><br/><img src="./assets/images/pfeil_gelb.png"><b> <a href="https://www.commerzbank.de/portal/de/unternehmerkunden/beratung-und-branchen/beratung/cashradar/cashradar.html" target="_blank">CashRadar</a></b><br><img src="./assets/images/pfeil_gelb.png"> <b><a href="http://comnet.intranet.commerzbank.com/comnet/de/privatundgeschftskunden/vertriebsmanahmenundverkaufsfrderung/musterthemenvisitenkarte_51/zielgruppen_3/sector_radar_uk/sector_radar_zur_beratung_von_unternehmerkunden.jsp" target="_blank">Sector:Radar</a></b><br><img src="./assets/images/pfeil_gelb.png"> <b><a href="https://www.nachfolge.commerzbank.de/" target="_blank">Nachfolge</a></b></p></aside></span>';
    }
  }

  ngOnInit(): void {
    this.context = this.getContext();
    this.getThemen(this.context);
    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);
    this.dataService.setLanguage("de")

    // console.log(this.context);

    if (this.context === 'uk') {
      // console.log('fett');
      this.h1Fett = 1;
    }
  }

  onSelect(thema: Thema): void {
    this.selectedThema = thema;
  }

  setFile(modul: Modul) {
    // console.log('THEMA');
    // console.log(modul);
    const temp: Modul[] = [];
    // Store the selection to the metadata
    localStorage.setItem('metadata', '');
    const metadata: Metadata = new Metadata; // .getMetadata()
    temp.push(modul);
    metadata.module = temp;
    metadata.storeMetadata(this.context);

    this.storeCurrentPresentationMode('thema');
    // this.storeCurrentPresentation(modul.link)
  }

  /*
  / Modal-Dialog für Regulatorische Hinweise
  */
  showModal(thema: Thema) {
    this.modal = -1;
    this.modaldialog = thema.modaldialog;
    /*var newWindow = this.nativeWindow.open('/#/link');
    newWindow.title = "TEST";
    newWindow.showModal();
    */
  }

  closeModal() {
    this.modal = -1;
  }




}



