import { Component, OnInit } from '@angular/core';
import { GlobalEventsManager} from '../services/GlobalEventsManager';
import { Commaster } from '../models/commaster';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [],
  templateUrl: 'feedback.html',
  styleUrls: []
})

export class FeedbackComponent extends Commaster implements OnInit {

  feedbacktext: string;

  constructor(public dataService: DataService) {
    super(dataService);
  }

  ngOnInit() {
    this.context = this.getContext();
    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);

  }

}
