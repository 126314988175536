import { Component, OnInit } from '@angular/core';
import { Vertriebsbox } from '../../models/vertriebsbox';
import { Clipboard } from 'ts-clipboard';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

@Component({
  selector: 'my-app',
  templateUrl: './editorpage.component.html',
})


export class EditorpageComponent implements OnInit {

  content = '';
  exportString = '';
  preview: Vertriebsbox;
  v1: Vertriebsbox;
  v2: Vertriebsbox;
  v3: Vertriebsbox;
  v4: Vertriebsbox;
  v5: Vertriebsbox;
  v6: Vertriebsbox;
  v7: Vertriebsbox;

  // constructor( private globalEventsManager: GlobalEventsManager) {
  constructor(private _sanitizer: DomSanitizer) {

    this.preview = new Vertriebsbox(1, 1, '', '', 1, '', '', '', '', '', '', '', '', '');
    // tslint:disable-next-line:max-line-length
    this.v1 = new Vertriebsbox(1, 1, 'Vertriebsbox1', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 1, 'dummy2','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v2 = new Vertriebsbox(1, 1, 'Vertriebsbox2', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 2, 'dummy2','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v3 = new Vertriebsbox(1, 1, 'Vertriebsbox3', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 3, 'dummy2','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v4 = new Vertriebsbox(1, 1, 'Vertriebsbox4', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 4, 'dummy2','Link 1','Link 2', 'Link 3','Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v5 = new Vertriebsbox(1, 1, 'Vertriebsbox5', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 5, 'dummy2','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v6 = new Vertriebsbox(1, 1, 'Vertriebsbox6', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 6, 'dummy1','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
    // tslint:disable-next-line:max-line-length
    this.v7 = new Vertriebsbox(1, 1, 'Vertriebsbox7', 'Ihre Unterstützung zur Erstellung professioneller Präsentationen.', 7, 'dummy1','Link 1','Link 2','Link 3', 'Link 4','http://link.to/1','http://link.to/2','http://link.to/3','http://link.to/4');
  }

  export() {
    Clipboard.copy(this.preview.getBoxtype())
    alert('Definition wurde in die Zwischenablage kopiert') //JSON.stringify(this.preview))
  }

  ngOnInit() {
    // this.globalEventsManager.showNavBar('admin');
  }

  getTemp(storage): string {
    localStorage.getItem('editordata' + storage)
    var temp = JSON.parse(localStorage.getItem('editordata' + storage));
    if (temp!=null) {
      if (temp.storename!=null) {
        return temp.storename;
      }
    }
    return '-';
}

  storeTemp(storage): void {
      localStorage.setItem('editordata' + storage , JSON.stringify(this.preview))
      this.showSnack()

  }

  getPreview(temp:Vertriebsbox) {
    return this._sanitizer.sanitize(SecurityContext.HTML, temp.getBoxtype())

  }

  loadTemp(storage): void {
      var temp = JSON.parse(localStorage.getItem('editordata' + storage))
      this.preview.storename = temp.storename
      this.preview.name = temp.name
      this.preview.content = temp.content
      this.preview.template = temp.template
      this.preview.image = temp.image
      this.preview.link1titel = temp.link1titel
      this.preview.link2titel = temp.link2titel
      this.preview.link3titel = temp.link3titel
      this.preview.link4titel = temp.link4titel
      this.preview.link1 = temp.link1
      this.preview.link2 = temp.link2
      this.preview.link3 = temp.link3
      this.preview.link4 = temp.link4
  }

  deleteTemp(storage): void {
    this.preview.storename = ''
    this.preview.name = ''
    this.preview.content = ''
    this.preview.template = ''
    this.preview.image = ''
    this.preview.link1titel = ''
    this.preview.link2titel = ''
    this.preview.link3titel = ''
    this.preview.link4titel = ''
    this.preview.link1 = ''
    this.preview.link2 = ''
    this.preview.link3 = ''
    this.preview.link4 = ''
    localStorage.setItem('editordata' + storage , JSON.stringify(this.preview))

}

  showSnack() {

          // Get the snackbar DIV
          var x = document.getElementById('snackbar')

          // Add the 'show' class to DIV
          x.className = 'show';

          // After 3 seconds, remove the show class from DIV
          setTimeout(function(){ x.className = x.className.replace('show', ''); }, 3000);


      }

}
