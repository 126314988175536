import { Component, Input } from '@angular/core';

import { Thema } from '../models/thema';

@Component({
  selector: 'thema-detail',
  
  template: `
    <div *ngIf="thema">
      <h2>{{thema.name}} details!</h2>
      <div><label>id: </label>{{thema.id}}</div>
      <div>
        <label>name: </label>
        <input [(ngModel)]="thema.name" placeholder="name"/>
      </div>
    </div>
  `
})

export class ThemaDetailComponent {
    @Input() thema: Thema;
}
