export const environment = {

  production: false,
  startpath: 'mainpage',
  envname: 'prod',
  version: '6.2.1 (04/2024)',

  baseHref: '/',

  excelbridge: 'http://192.168.1.10:8080/master_1.ppsm',

  KontaktfolienAnzeigen: true,
  WeiterempfehlungsseiteAnzeigen: true,

  fertigeFolienDisclaimer: false,
  modularDisclaimer: true,

  // VV (Vermögensverwaltung aktiv? Eher WM)
  withVV: true,

  slidepreviews: true,

  deckblattfolder: 'assets/images/deckblattbilder/',
  startImagePath: '/assets/images/',
  docurl: '/',
  contacturl: 'h:\\team\\',
  //excelbridge:      'https://collab-emea.collaboration.intranet.commerzbank.com/sites/SAMGermany/Public/combook/combook_365.xlsm',

  // Homepage-Properties
  homepage_title: 'Herzlich Willkommen im Wealth Management',
  homepage_title_en: '',
  homepage_subtitle: 'Ihre Unterstützung bei der Erstellung professioneller Kundenpräsentationen.',
  homepage_subtitle_en: '',

  kontaktfolie_beratername: "Beratername",
  kontaktfolie_Standort: "FK Standort",
  kontaktfolie_Standort_placeholder: "z.B. Niederlassung xy, Standort, usw",
  kontaktfolie_Kundenname: "Kundenname",
  kontaktfolie_Firmenname: "Firmenname",

  wm_english: 'http://192.168.1.10:8080/',
  wm_base: 'http://192.168.1.10:8080/',
  uk_base: 'http://192.168.1.10:8080/',
  ik_base: 'http://192.168.1.10:8080/',

};
