import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { GlobalEventsManager } from '../services/GlobalEventsManager';
import { Customerdata } from '../models/customerdata';
import { Modul } from '../models/modul';
import { KundeService } from '../kunde/kunde.service';
import { SharedService } from '../services/shared.service';
import { Commaster } from '../models/commaster';
import { Metadata } from '../models/metadata';
import { Clipboard } from 'ts-clipboard';
import { Combook } from '../models/combook';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'my-app',
  providers: [KundeService],
  templateUrl: 'kunde.html',
  styleUrls: ['kunde.component.css']
})


export class KundeComponent extends Commaster implements OnInit {

  sprache = 'de'

  title = 'Kunden- und Beraterdaten';
  subtitle = 'Vor der Erstellung der gewünschten Unterlage überprüfen und ergänzen Sie bitte folgende Angaben:';

  title_en = 'Client and advisor details';
  subtitle_en = 'Please check and add the following details before creating the document:';

  KontaktfolienAnzeigen = environment.KontaktfolienAnzeigen
  WeiterempfehlungsseiteAnzeigen = environment.WeiterempfehlungsseiteAnzeigen

  pmode: string;

  customerdata: Customerdata = new Customerdata;
  selectionArray: Modul[] = [];
  caller = '';
  arguments = 'x';

  standort: string

  team: string[] = [];                // bereits ausgewählte Folien zur Auswahl
  teamfolienSelected: string[] = [];  // angeklickte Folien

  deckblaetter: string[] = [];
  deckblatt: string;
  showDeckblaetter = 1;

  showWeiterempfehlungpage: number = -1;
  weiterempfehlungsCheck = this.url + this.baseHref + "/" + this.leererCheck;

  showAnlageberatung: number = -1;
  showAnlageberatungCheckbox: number = -1;

  showContactpage: number = -1;
  showContactCheck = this.url + this.baseHref + "/" + this.leererCheck;
  contactStartCheck = this.url + this.baseHref + "/" + this.gelberCheck;
  contactEndCheck = this.url + this.baseHref + "/" + this.leererCheck;
  showContactPositionStart = this.url + this.baseHref + "/" + this.leererCheck;
  showContactPositionEnd = this.url + this.baseHref + "/" + this.leererCheck;
  showContactpositionChooser = 1;
  contactPageAt = 'end';

  anreden = ['', 'Herrn', 'Frau', 'Mr.', 'Mrs.'];

  titel = ['', 'Prof. Dr.', 'Prof.', 'Dr.'];

  modulauswahlzurueck = ''
  weiter = ''
  zurueck = ''
  Anrede = ''
  AnredeBox = ''
  Titel = ''
  Kundenname = ''
  Firmenname = ''
  Ort = ''
  Datum = ''
  Beratername = ''
  Niederlassung = ''
  Kontaktseite = ''
  Weiterempfehlung = ''
  Deckblaetter = ''
  Favoriten = ''
  Erstellen = ''
  hinweis = ''
  standort_placeholder = ''
  berater_placeholder = ''

  constructor(public dataService: DataService, @Inject(DOCUMENT) private document, private sharedService: SharedService) {
    super(dataService);
    this.url = document.location.protocol + '//' + document.location.host;
  }

  ngOnInit() {

    this.context = this.getContext();
    // console.log("context " + this.context);
    this.caller = this.getCaller();
    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);

    this.dataService.getLanguage().then((lang) => {
      if (lang !== null && lang !== undefined && lang !== '') {
        this.sprache = lang
      } else {
        this.sprache = 'de'
      }
      if (this.sprache === 'de') {
        this.modulauswahlzurueck = "Modulauswahl zurücksetzen"
        this.weiter = 'Weiter'
        this.zurueck = 'Zurück'
        this.Anrede = "Anrede"
        this.AnredeBox = "Bitte auswählen"
        this.Titel = 'Titel'
        this.Kundenname = environment.kontaktfolie_Kundenname
        this.Firmenname = environment.kontaktfolie_Firmenname
        this.Ort = 'Ort des Gesprächs'
        this.Datum = 'Datum des Gesprächs'
        this.Beratername = 'Beratername'
        this.Niederlassung = 'FK Niederlassung'
        this.Kontaktseite = 'Kontaktseite anfügen'
        this.Weiterempfehlung = 'Weiterempfehlung'
        this.Deckblaetter = 'Deckblätter'
        this.Favoriten = 'Zu Favoriten hinzufügen'
        this.Erstellen = 'Präsentation erstellen'
        this.hinweis = 'Die Kunden- und Beraterdaten erscheinen auf dem Deckblatt. Der Standort und der Kundenname jeweils in der Fußzeile der Präsentation'
        this.standort_placeholder = 'z.B. Niederlassung xy, Standort, usw.'
        this.berater_placeholder = 'Vor- und Nachname'
      } else {
        this.modulauswahlzurueck = "Reset selection"
        this.weiter = 'Next'
        this.zurueck = 'Back'
        this.Anrede = 'Salutation'
        this.AnredeBox = "Please select"
        this.Titel = 'Title'
        this.Kundenname = 'Client name'
        this.Firmenname = 'Company name'
        this.Ort = 'Location of the meeting'
        this.Datum = 'Date of the meeting'
        this.Beratername = 'Advisor name'
        this.Niederlassung = 'Branch'
        this.Kontaktseite = 'Upload contact slide'
        this.Weiterempfehlung = 'Recommendation'
        this.Deckblaetter = 'Title slides'
        this.Favoriten = 'Add to favorites'
        this.Erstellen = 'Create presentation'
        this.standort_placeholder = 'i.e. Branch xy, City, ...'
        this.berater_placeholder = 'First- and lastname'
        this.hinweis = 'The customer and advisor details appear on the cover sheet. The location and client name appear in the footer of the presentation'
      }
      // console.log("Sprache set " + this.sprache)
    })


    this.team = this.getTeamfolien();
    this.modulemode = this.getModulemode();
    // console.log(this.context);
    let metadata: Metadata = Metadata.getMetadata(this.getContext());
    // console.log(metadata);
    this.customerdata = metadata.customerdata;
    this.showWeiterempfehlungpage = metadata.showWeiterempfehlungpage;
    this.showContactpage = metadata.showContactpage;
    this.teamfolienSelected = metadata.kontaktfolien;
    this.showAnlageberatung = metadata.showAnlageberatung;
    this.contactPageAt = metadata.kontaktfolie_position;
    this.selectionArray = JSON.parse(localStorage.getItem('currentagenda'));
    this.customerdata = metadata.customerdata;
    this.showContactpositionChooser = 1;
    //this.kontaktfolie_position = metadata.kontaktfolie_position;
    //metadata.kontaktfolien = t.kontaktfolien;
    // console.log(metadata);

    //
    // DRINGEND REFACTOREN!!!
    //

    this.pmode = this.getCurrentPresentationMode();
    // console.log(this.pmode)
    if (this.showWeiterempfehlungpage > 0) {
      this.weiterempfehlungsCheck = this.url + this.baseHref + "/" + this.gelberCheck;
    }

    if (this.showContactpage > 0) {
      this.showContactCheck = this.url + this.baseHref + "/" + this.gelberCheck;
    }

    // console.log(this.contactPageAt)
    if (this.contactPageAt === 'start') {
      this.contactPageAt = 'start';
      this.showContactPositionStart = this.url + this.baseHref + "/" + this.gelberCheck;
    }
    if (this.contactPageAt === 'end' || this.contactPageAt === '' || this.contactPageAt === undefined) {
      this.contactPageAt = 'end';
      this.showContactPositionEnd = this.url + this.baseHref + "/" + this.gelberCheck;
    }


    if (environment.kontaktfolie_Standort !== "") {
      this.standort = environment.kontaktfolie_Standort;

    } else {
      if (this.modulemode === this.UK_MODULE || this.modulemode === this.PB_MODULE) {
        this.standort = 'Standort';
      } else {
        this.standort = 'Wealth Management Standort';
      }
    }

    if (this.modulemode === this.WM_MODULE || this.modulemode === this.UK_MODULE || this.modulemode === this.PB_MODULE) {
      this.deckblatt = metadata.deckblatt;

      // console.log("deck1:" + this.deckblatt);
      // console.log(metadata)
      if (this.deckblatt === null || this.deckblatt === undefined || this.deckblatt === '') {
        // console.log("1");
        this.deckblatt = 'Deckblatt_1_' + this.sprache + '.pptx';
        metadata.deckblatt = this.deckblatt;
        metadata.storeMetadata(this.context);
      }
      this.getDeckblaetter().then((deckblaetter) => {
        this.deckblaetter = deckblaetter
      })
    }
    if (this.modulemode === this.WM_MODULE) {
      this.showAnlageberatungCheckbox = -1;
    }

    // console.log("mm>" + this.modulemode);
    if ((this.modulemode === this.WM_MODULE || this.modulemode === this.UK_MODULE) && this.pmode === 'thema') {
      this.showContactpositionChooser = 0;
    }

    if (this.pmode === 'thema' || this.pmode === 'english') {
      this.showAnlageberatungCheckbox = -1;
      this.showDeckblaetter = -1;
    }

    if (this.modulemode === this.WM_ENGLISH || this.modulemode === this.UK_ENGLISH) {
      this.showContactpositionChooser = 0;
      this.showAnlageberatungCheckbox = -1;
      this.showDeckblaetter = -1;
    }

    if (this.pmode === 'modul') {
      this.showDeckblaetter = 1;
      this.getDeckblaetter().then((deckblaetter) => {
        this.deckblaetter = deckblaetter
      })

      // console.log("this.pmode")
      // console.log(this.pmode)
      // console.log("---->>MODUL----")
    }


    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    // console.log(this.arguments);
  }

  storeCustomerData() {
    this.storeCustomerdataTemp();
  }




  change(event: any) {

    // console.log(event.target.files);
    for (let f of event.target.files) {
      // console.log(f)
      if (!this.team.includes(f.name)) {
        this.team.push(f.name)
      }
      this.storeTeamfolien(this.team)
    }
    //this.metadata.kontaktfolien = this.teamfolienSelected
  }


  switchDeckblatttoggle(image: any, clickedtoggle: string, twins: string[]): void {
    let metadata: Metadata = Metadata.getMetadata(this.context);
    this.switchMultitoggle(image, clickedtoggle, twins);
    metadata.deckblatt = clickedtoggle;
    metadata.storeMetadata(this.context);

    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
  }

  getDeckblaetter() {
    console.log("getDeckblätter")

    let language = "de"

    return this.dataService.getPfad().then((pfad) => {
      console.log("getDeckblätter for " + language)
      if (pfad === "wm_module_en") {
        language = "en"
      }
      const deckblaetter = [];
      deckblaetter.push('Deckblatt_1_' + language + '.pptx');
      deckblaetter.push('Deckblatt_2_' + language + '.pptx');
      deckblaetter.push('Deckblatt_3_' + language + '.pptx');

      // console.log(deckblaetter)
      return deckblaetter;
    })
  }

  public isDeckblattChecked(deckblattCheck: string): String {

    if (this.deckblatt === deckblattCheck) {
      return this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      return this.url + this.baseHref + "/" + this.leererCheck;
    }
  }

  getDeckblattThumb(deckblatt: string) {
    // console.log(this.modulemode)
    // || this.modulemode === 'english_module'
    let url = ''
    if (this.modulemode === this.UK_MODULE || this.modulemode === this.WM_MODULE || this.modulemode === this.WM_MODULE_EN || this.modulemode === this.PB_MODULE || this.modulemode === 'english_module' || this.modulemode === this.IK_MODULE || this.modulemode === 'ik_module_en' || this.modulemode === 'ik_module_de') {
      url = this.url + this.deckblattfolder + deckblatt.replace('.pptx', '').replace('.ppt', '') + ".png";
    }
    /*else if (this.modulemode === this.UK_MODULE) {
        url = this.url + '/GKBOOK/Inhalt/' + this.deckblattfolder + deckblatt.replace('.pptx','').replace('.ppt','') + ".jpg";
    } */

    /*else if (this.modulemode === this.IK_MODULE || this.modulemode === 'ik_module_en' || this.modulemode === 'ik_module_de') {
        url = this.url + '/WMbook/Module/IK/' + this.deckblattfolder + deckblatt.replace('.pptx','').replace('.ppt','') + ".png";
    }
    */

    // console.log("-------getDeckblattThumb-----")
    // console.log(this.modulemode)
    // console.log(url)
    return url;
  }

  teamfolienLoeschen() {
    this.team = [];
    this.storeTeamfolien(this.team);
  }

  public isTeamfolieChecked(teamfolie: string): String {

    if (this.teamfolienSelected != undefined && this.teamfolienSelected.includes(teamfolie)) {
      return this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      return this.url + this.baseHref + "/" + this.leererCheck;
    }
  }

  public isChecked(check: number): String {
    if (check > 0) {
      return this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      return this.url + this.baseHref + "/" + this.leererCheck;
    }
  }



  simpleToggle(image: any): void {

    if (image.src === this.url + this.baseHref + "/" + this.leererCheck) {
      image.src = this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      image.src = this.url + this.baseHref + "/" + this.leererCheck;
    }

    this.storeCustomerdataTemp();
  }


  contactToggle(image: any): void {

    // console.log("ct");
    if (this.showContactpage === 0) {
      this.showContactpage = -1;
    }
    this.showContactpage *= -1;
    this.simpleToggle(image);

    let metadata: Metadata = Metadata.getMetadata(this.context);
    metadata.kontaktfolien = this.teamfolienSelected;
    metadata.storeMetadata(this.context);

    //this.metadata.kontaktfolien = this.teamfolienSelected

    this.storeCustomerdataTemp();

    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    // console.log('contactToggle ' + this.showContactpage + ' ' + this.showContactpositionChooser);

  }


  teamToggle(image: any, teamfolie: string): void {

    // console.log("tt");
    if (this.teamfolienSelected == undefined) {
      this.teamfolienSelected = [];
    }
    if (this.teamfolienSelected.includes(teamfolie)) {
      this.removeStringFromStringarray(teamfolie, this.teamfolienSelected);
    } else {
      this.teamfolienSelected.push(teamfolie);
    }

    this.simpleToggle(image);

    let metadata: Metadata = Metadata.getMetadata(this.context);
    metadata.kontaktfolien = this.teamfolienSelected
    metadata.storeMetadata(this.context);

  }

  weiterempfehlungsToggle(image: any): void {

    this.showWeiterempfehlungpage *= -1;
    this.simpleToggle(image);

    let metadata: Metadata = Metadata.getMetadata(this.context);
    metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
    metadata.storeMetadata(this.context);

    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    //this.metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
  }

  anlageberatungsToggle(image: any): void {

    this.showAnlageberatung *= -1;
    this.simpleToggle(image);

    let metadata: Metadata = Metadata.getMetadata(this.context);
    metadata.showAnlageberatung = this.showAnlageberatung;
    metadata.storeMetadata(this.context);

    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    // this.metadata.showAnlageberatung = this.showAnlageberatung;
  }

  radioToggle(defButton: string): void {

    const image_1 = (<HTMLImageElement>document.getElementById('radio1'));
    const image_2 = (<HTMLImageElement>document.getElementById('radio2'));

    if (this.contactPageAt === 'start') {
      this.contactPageAt = 'end';
      image_1.src = this.url + this.baseHref + "/" + this.leererCheck;
      image_2.src = this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      this.contactPageAt = 'start';
      image_1.src = this.url + this.baseHref + "/" + this.gelberCheck;
      image_2.src = this.url + this.baseHref + "/" + this.leererCheck;
    }

    this.storeCustomerdataTemp()

  }

  storeCustomerdataTemp(): void {

    // console.log('store customerdata ' + this.customerdata)
    this.storeKundendaten(JSON.stringify(this.customerdata));

    let metadata = Metadata.getMetadata(this.context);
    if (metadata != undefined) {
      metadata.customerdata = this.customerdata;
      metadata.showWeiterempfehlungpage = this.showWeiterempfehlungpage;
      metadata.showAnlageberatung = this.showAnlageberatung;
      metadata.showContactpage = this.showContactpage;
      metadata.kontaktfolien = this.teamfolienSelected;
      metadata.kontaktfolie_position = this.contactPageAt;
      metadata.storeMetadata(this.context);
    } else {
      // console.log('metadata undefined')
    }

    this.arguments = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);

  }

  loadCustomerdataTemp(): void {
    // console.log('load customerdata')
    this.customerdata = JSON.parse(this.getKundendaten());

  }

  createpresentation(): void {
    this.storeCustomerdataTemp;
    this.loadCustomerdataTemp;
    const metadata = Metadata.getMetadata(this.context);

    const args = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);

    Clipboard.copy(args);
    // console.log(args);
    window.open(this.docurl + this.pptname, '_blank');
  }

  createpresentation365(): void {
    this.storeCustomerdataTemp;
    this.loadCustomerdataTemp;
    const metadata = Metadata.getMetadata(this.context);

    const args = metadata.getMetadataAsVBAString(this.context, this.getLanguage(), this.pmode, this.docurl, this.contacturl);
    Clipboard.copy(args);
    // console.log(args);
    // console.log(environment.excelbridge);
    window.open(environment.excelbridge, '_blank');
  }

  copyTo() {
    Clipboard.copy('HalliHallo');
    // console.log('metadata copied to clipboard ')
  }

  goBack() {
    window.history.back();
  }

}

// combookdata||1||http://192.168.1.25/presentations/standort.pptx;http://192.168.1.25/presentations/standort.pptx;http://192.168.1.25/presentations/vorstand.pptx;||;;xcvb;;xcvb;xcvb;|| | ||1|Commerzbank Overview;||


