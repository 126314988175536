import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainpageRoutingModule } from './mainpage/mainpage-routing.module';
import { MainpageModule } from './mainpage/mainpage.module';

import { ModulpageComponent } from './modulpage/modulpage/modulpage.component';

import { ImpressumComponent } from './impressum/impressum.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CloseComponent } from './close/close.component';

import { WMComponent } from './wmpage/wm.component';
import { WMService } from './wmpage/wm.service';

import { UKComponent } from './unternehmerkunden/uk.component';
import { UKService } from './unternehmerkunden/uk.service';

import { IKComponent } from './internationalekunden/ik.component';
import { IKService } from './internationalekunden/ik.service';

import { AgendaComponent } from './agenda/agenda.component';
import { AgendaService } from './agenda/agenda.service';

import { TextblockComponent } from './textblock/textblock.component';
import { TextblockService } from './textblock/textblock.service';

import { KundeComponent } from './kunde/kunde.component';
import { KundeService } from './kunde/kunde.service';

import { EnglishComponent } from './englishpage/english.component';

import { EditorpageComponent } from './editorpage/editorpage/editorpage.component';

import { ThemaDetailComponent } from './themenpage/thema-detail.component';
import { ThemenComponent } from './themenpage/themen.component';
import { ThemaService } from './themenpage/themen.service';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  // set the modulepages also in commaster
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'feedback',
    component: FeedbackComponent
  },
  {
    path: 'close',
    component: CloseComponent
  },
  {
    path: 'wm_module',
    component: ModulpageComponent
  }, {
    path: 'wealthmanagement',
    component: WMComponent
  },
  {
    path: 'wm_module_en',
    component: ModulpageComponent
  },
  {
    path: 'uk_module',
    component: ModulpageComponent
  },
  {
    path: 'ik_module_de',
    component: ModulpageComponent
  },
  {
    path: 'ik_module_en',
    component: ModulpageComponent
  },
  {
    path: 'pb_module',
    component: ModulpageComponent
  },
  {
    path: 'combook',
    component: WMComponent
  },
  {
    path: 'unternehmerkunden',
    component: UKComponent
  },
  {
    path: 'internationalekunden',
    component: IKComponent
  },
  {
    path: 'english_wm',
    component: ModulpageComponent
  },
  {
    path: 'english_uk',
    component: EnglishComponent
  },
  {
    path: 'agenda',
    component: AgendaComponent
  },
  {
    path: 'kunde',
    component: KundeComponent
  },
  {
    path: 'textblock',
    component: TextblockComponent
  },
  {
    path: 'themen',
    component: ThemenComponent
  },
  {
    path: 'editorpage',
    component: EditorpageComponent
  }
  ,
  { path: '**', redirectTo: '/' + environment.startpath, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
