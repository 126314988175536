import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Modul } from '../models/modul';
import { Thema } from '../models/thema';
import { environment } from 'src/environments/environment';


@Component({
  template: ''
})
export class Commaster implements OnInit {

  // 2024-04-02
  public pfad: string = ''   // Aktueller Pfad zur Unterscheidung Englisch/Deutsch, etc.

  context = '';
  modulemode = '';
  startImagePath = '';

  url = '';
  docurl = '';
  pathname = '';
  deckblaetterUrl = '';
  contacturl = '';

  textbausteinpdf = '';

  module: Modul[] = [];
  selectedModul: Modul;
  idliste = '';

  version = '';
  pptname = '';
  pptname_365 = '';

  baseHref = environment.baseHref;

  public leererCheck = 'assets/images/check_leer.png';
  public pfeilGelbKasten = 'assets/images/pfeil_gelb_kasten.png';
  public gelberCheck = 'assets/images/check_gelb.png';

  public menuClosed = 'assets/images/pfeil_klein_rechts.png';
  public menuOpen = 'assets/images/pfeil_klein_unten.png';

  public DEUTSCH = 'de';
  public ENGLISH = 'en';

  public WM = 'wm';
  public WM_MODULE = 'wm_module';
  public WM_MODULE_EN = 'wm_module_en';
  public UK_MODULE = 'uk_module';
  public IK_MODULE = 'ik_module';
  public ENGLISH_MODULE = 'english_module';

  public WM_ENGLISH = 'WM_ENGLISH';
  public UK_ENGLISH = 'UK_ENGLISH';

  public IK_MODULE_DE = 'ik_module_de';
  public IK_MODULE_EN = 'ik_module_en';

  public PB_MODULE = 'pb_module';

  wm_english = '';
  wm_base = '';

  uk_base = '';
  ik_base = '';

  deckblattfolder = 'assets/images/deckblattbilder/';

  showLanguageSwitch = -1;

  constructor(public dataService: DataService) {

    this.modulemode = this.getModulemode();
    this.context = this.getContext();
    // console.log('commaster constructor' + this.context + '-' + this.modulemode);

    /////////////////////////////// ENVIRONMENTAL STUFF ////////////////////////
    this.version = environment.version;
    //this.pptname = environment.pptname
    //this.pptname_365 = environment.ppt365

    this.url = document.location.protocol + '//' + document.location.host;
    this.baseHref = environment.baseHref
    this.startImagePath = environment.startImagePath
    this.deckblattfolder = environment.baseHref + environment.deckblattfolder;
    this.docurl = this.url + this.baseHref + environment.docurl;

    this.wm_base = environment.wm_base;
    this.wm_english = environment.wm_english;
    this.uk_base = environment.uk_base;
    this.ik_base = environment.ik_base;

    /*
    if (environment.envname === "stage") {

      this.wm_base = "/";
      this.wm_english = '/Englisch/';
      this.uk_base = '/GKBOOK/Inhalt/';
      this.ik_base = '/WMbook/Module/IK/';

    } else {

      this.wm_english= 'https://commerzbank.sharepoint.com/sites/samgermany/ComBook%20FK/combook/index.html';
      this.wm_base= 'https://commerzbank.sharepoint.com/sites/samgermany/ComBook%20FK/combook/index.html';
      this.uk_base= 'https://commerzbank.sharepoint.com/sites/samgermany/ComBook%20FK/combook/index.html';
      this.ik_base= 'https://commerzbank.sharepoint.com/sites/samgermany/ComBook%20FK/combook/index.html';
 }
 */

    /*
  } else if (env === 'coba_test') {

    this.baseHref = '/sites/WMbook/Module/App/Test';
    this.startImagePath = "/sites/WMbook/Module/App/Online/assets/images/"
    this.url = document.location.protocol + '//' + document.location.host;

    this.deckblattfolder = this.baseHref + '/assets/images/deckblattbilder/';

    this.docurl = this.url + this.baseHref + '/docbase/';

    this.contacturl = 'h:\\team\\';

    this.wm_english = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.wm_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.uk_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.ik_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';

  } else if (env === 'schramm.co') {

    this.url = document.location.protocol + '//' + document.location.host;
    this.startImagePath = "/sites/WMbook/Module/App/Online/assets/images/"
    this.docurl = this.url + '/docbase/';
    //this.docurl = 'https://combook.schramm.co/docbase/';

    this.contacturl = 'h:\\team\\';

    this.baseHref = '/';

    this.wm_base = this.url + "/";
    this.wm_english = this.url + '/Englisch/';
    this.uk_base = this.url + '/GKBOOK/Inhalt/';
    this.ik_base = this.url + '/WMbook/Module/IK/';

  } else if (env === 'server') {

    this.baseHref = '/sites/WMbook/Module/App/Online';
    this.startImagePath = "/sites/WMbook/Module/App/Online/assets/images/"
    this.url = document.location.protocol + '//' + document.location.host;

    this.deckblattfolder = this.baseHref + '/assets/images/deckblattbilder/';

    this.docurl = this.url + this.baseHref + '/docbase/';

    this.contacturl = 'h:\\team\\';

    this.wm_english = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.wm_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.uk_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
    this.ik_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';

    /*
                this.url = document.location.protocol + '//' + document.location.host;
                this.docurl = 'http://combook.online/docbase/';
                this.contacturl = 'h:\\team\\';

                this.baseHref = '/';

                this.wm_english = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
                this.wm_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
                this.uk_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/GKBOOK/Inhalt/';
                this.ik_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';


  }
    /*else if (env === 'testcoba') {

        this.baseHref = '/sites/WMbook/Module/App/Test/';

        this.url = document.location.protocol + '//' + document.location.host;
        this.docurl = document.location.protocol + '//' + document.location.host + this.baseHref + 'docbase/';
        this.contacturl = 'h:\\team\\';

        this.wm_english = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
        this.wm_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
        this.uk_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/GKBOOK/Inhalt/';
        this.ik_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';

    }
    else {

      this.baseHref = '/sites/WMbook/Module/App/Online/';

      this.url = document.location.protocol + '//' + document.location.host;
      this.docurl = document.location.protocol + '//' + document.location.host + this.baseHref + 'docbase/';
      this.contacturl = 'h:\\team\\';

      this.wm_english = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
      this.wm_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
      this.uk_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';
      this.ik_base = 'https://collab-emea.collaboration.intranet.commerzbank.com/sites/WMbook/Module/';

    }
*/
    localStorage.setItem('wm_english', this.wm_english);
    localStorage.setItem('wm_base', this.wm_base);
    localStorage.setItem('uk_base', this.uk_base);
    localStorage.setItem('ik_base', this.ik_base);

    this.textbausteinpdf = this.wm_base + 'Standard/texte.pdf';

    /////////////////////////////// \ENVIRONMENTAL STUFF ////////////////////////

    const path = document.location.href.split('/');
    // console.log(document.location.href + " " + path.length)
    if (path.length > 0) {
      this.pathname = path[path.length - 1];
    }

    // tslint:disable-next-line:max-line-length
    if (this.pathname === this.PB_MODULE || this.pathname === this.WM_MODULE || this.pathname === this.WM_MODULE_EN || this.pathname === this.IK_MODULE || this.pathname === this.UK_MODULE) {
      this.storeModulemode(this.pathname);
      // console.log('>' + this.pathname);
    }

    // console.log('/commaster constructor ' + this.context + '-' + this.modulemode);
  }


  ngOnInit() {

    this.context = this.getContext();
    this.modulemode = this.getModulemode();
    if (this.context === "ik") {
      this.showLanguageSwitch = 1;
    } else {
      this.showLanguageSwitch = -1;
    }
  }

  /**
   * plotData
   */
  public plotData(module: Modul[]) {

    var output = "<code>data-dump\n";
    output += "link (id); parent (link); name; ...\n\n"

    for (let entry of module) {

      for (let sub1entry of entry.submodule) {
        if (sub1entry.submodule.length === 0) {
          output += entry.name + ";" + sub1entry.path + sub1entry.link + ";" + entry.name + ";" + sub1entry.name + "\n|";
        }
        for (const sub2entry of sub1entry.submodule) {
          if (sub2entry.submodule.length === 0) {
            output += entry.name + ";" + sub2entry.path + sub2entry.link + ";" + entry.name + ";" + sub1entry.name + ";" + sub2entry.name + "\n|";
          }
          for (const sub3entry of sub2entry.submodule) {
            output += entry.name + ";" + sub3entry.path + sub3entry.link + ";" + entry.name + ";" + sub1entry.name + ";" + sub2entry.name + ";" + sub3entry.name + "\n|";
          }
        }
      }
    }
    return output + "</code>";
  }



  simpleToggle(image: any): void {

    // console.log("master: simpletoggle " + image.src + " <>" + this.url + this.leererCheck)
    if (image.src === this.url + this.baseHref + "/" + this.leererCheck) {
      image.src = this.url + this.baseHref + "/" + this.gelberCheck;
      // console.log("master: simpletoggle setGELB")

    } else {
      image.src = this.url + this.baseHref + "/" + this.leererCheck;
      // console.log("master: simpletoggle setLEER")

    }
  }

  switchMultitoggle(image: any, clickedtoggle: string, twins: string[]): void {

    // console.log("master: switchMultitoggle " + image.src + " <>" + this.url + this.leererCheck)
    for (const t of twins) {
      const lbl = (<HTMLImageElement>document.getElementById('chk' + t));
      lbl.src = this.url + this.baseHref + "/" + this.leererCheck;
    }
    image.src = this.url + this.baseHref + "/" + this.gelberCheck;


  }


  castToModul(obj: any): Modul {
    const temp: Modul = new Modul;
    temp.id = obj.id;
    temp.name = obj.name;
    temp.parent = obj.parent;
    temp.mandatoryinfo = obj.mandatoryinfo;
    temp.link = obj.link;
    temp.level = obj.level;
    temp.displayname = obj.displayname;
    temp.mandatoryAggregate = obj.mandatoryAggregate;
    temp.regulatory = obj.regulatory;
    temp.submodule = obj.submodule;
    return temp;
  }

  // Context means: WM, GK, PK, etc...
  storeContext(context: string) {
    // console.log("--------storeContext----------")
    // console.log(context)
    // console.log("------------------")
    this.context = context;
    localStorage.setItem('context', context);
  }

  getContext() {
    return localStorage.getItem('context');
  }

  // Context means: WM, GK, PK, etc...
  storeModulemode(modulemode: string) {
    // console.log("storemodulMode:" + modulemode)
    this.modulemode = modulemode;
    localStorage.setItem('modulemode', modulemode);
    return modulemode;
  }
  getModulemode() {
    return localStorage.getItem('modulemode');
  }

  // Language-setting
  storeLanguage(language: string) {
    localStorage.setItem('language', language);
  }
  getLanguage() {
    let language = localStorage.getItem('language');
    if (language === undefined || language === null) {
      language = this.DEUTSCH;
    }
    // console.log(language)
    return language;
  }

  changeLanguage(language: string) {
    //this.resetSelectionFromRemote();
    this.storeLanguage(language);
  }

  // store a list of modules, seperated by semicolon
  resetSelectionFromRemote() {
    this.clearSelection();
  }

  clearSelection() {
    if (this.modulemode === '') {
      // console.log('NO CONTEXT SET!');
      return
    }
    this.clearSelectionFor(this.getContext(), this.modulemode);

  }
  /*
    loadModule(nav) {
      // console.log("LoadModule for " + nav)
      if (nav===null || nav===undefined || nav==='') {
        this.dataService.getNavigationMode().then((nav)=>{
          this.dataService.resetAuswahl()
          return this.modulpageService.getModuleFromJSON(nav)
        })
      } else {
        this.dataService.setNavigationMode(nav).then((_)=>{
          this.dataService.resetAuswahl()
          return this.modulpageService.getModuleFromJSON(nav)
        })
  
      }
  
    }
  */
  resetChoice() {
    //   this.loadModule("")
    //alert("1", "2")

    // console.log("Reset choice")
    for (let entry of this.module) {
      entry.is_checked = false
      //entry.color = '#333333'
      for (let subentry of entry.submodule) {
        subentry.is_checked = false
        //subentry.color = '#333333'
        for (let subsubentry of subentry.submodule) {
          subsubentry.is_checked = false
          //subsubentry.color = '#333333'
        }
      }
    }
    // console.log(this.module)
    /*this.dataService.getPfad().then((pfad) => {
      this.dataService.getModules(pfad).then((module) => {
        this.module = module
        this.ngOnInit()
      })
    })*/
    //    this.storeForAgenda()
    //    this.ngOnInit()


    /*
    this.selectionTree = [];
    this.selectionArray = [];
    this.tempArray = [];
    this.clearSelection();

    for (let entry of this.module) {
      entry.showSubTree = false;
      const lblx = (<HTMLSpanElement>document.getElementById('lbl' + entry.id));
      if (lblx != null) {
        lblx.style.color = '#333';
      }
    }
    */

    //window.location.reload();
  }


  storeForAgenda() {
    // console.log("StoreForAgenda")
    this.dataService.setModules(this.pfad, this.module)
    //this.storeAgendaModel(this.selectionArray);
    //this.storeAgendaModel(this.module);

    this.storeCurrentPresentationMode('modul');
  }



  clearSelectionForAll() {
    this.clearSelectionFor('wm', 'wm_module');
  }

  clearSelectionFor(context, modulemode) {
    // console.log('clearSelection for ' + context + ' ' + modulemode);
    localStorage.setItem('AUSWAHL', '');
    localStorage.setItem('metadata' + context, '');
    localStorage.setItem('selection' + modulemode, '');
    localStorage.setItem('selectionModel' + modulemode, '');
    localStorage.setItem('idliste' + modulemode, ''); // +this.context
    localStorage.setItem('LASTagendaModel', '');
  }

  storeTeamfolien(teamfolien: string[]) {
    // console.log('teamfolien: ' + JSON.stringify(teamfolien))
    localStorage.setItem('teamfolien', JSON.stringify(teamfolien));
  }
  getTeamfolien(): string[] {

    var temp = '';
    temp = localStorage.getItem('teamfolien');

    if (temp != null && temp !== '') {
      // console.log("teamfolien " + temp)
      return JSON.parse(temp);
    }

    // console.log("teamfolien empty")
    return [];
  }


  // Store the select as json-string, and restore it from the string
  storeSelectionModel(selection: Modul[]) {
    // console.log(this.modulemode + ' ' + JSON.stringify(selection));
    if (this.modulemode === '') {
      // console.log('NO CONTEXT SET!');
    }
    localStorage.setItem('selectionModel' + this.modulemode, JSON.stringify(selection));
  }

  getSelectedAsList(module) {
    // console.log("getModuleById: " + id);

    const selected: any[] = []

    for (const entry of module) {
      if (entry != null && entry.is_checked && entry.submodule.length == 0) {
        selected.push(entry)
      }
      for (const subentry of entry.submodule) {
        if (subentry != null && subentry.is_checked && subentry.submodule.length == 0) {
          selected.push(subentry)
        }
        for (const sub1entry of subentry.submodule) {
          if (sub1entry != null && sub1entry.is_checked && sub1entry.submodule.length == 0) {
            selected.push(sub1entry)
          }
          for (const sub2entry of sub1entry.submodule) {
            if (sub2entry != null && sub2entry.is_checked && sub2entry.submodule.length == 0) {
              selected.push(sub2entry)
            }
          }
        }
      }
    }
    // console.log("getSelectedAsList")
    // console.log(selected)

    return selected;

  }

  getSelectionModel(): Modul[] {
    let temp = '';
    if (this.getModulemode() === '') {
      // console.log('NO1 CONTEXT SET!');
    }
    // console.log(this.getModulemode())
    temp = localStorage.getItem('selectionModel' + this.getModulemode());
    // console.log("getSelectionModel")

    if (temp != null && temp !== '') {
      // console.log(temp)
      return JSON.parse(temp);
    }
    // console.log("temp leer")
    return [];
  }

  storeCurrentPresentationMode(mode: string) {
    if (this.context === '') {
      // console.log('NO CONTEXT SET!');
    }
    localStorage.setItem('CurrentPresentationMode' + this.context, mode);
  }
  getCurrentPresentationMode(): string {
    if (this.context === '') {
      // console.log('NO CONTEXT SET!');
    }
    return localStorage.getItem('CurrentPresentationMode' + this.context);
  }

  storeRegulatoryAccepted(modul: Modul) {
    if (this.context === '') {
      // console.log('NO CONTEXT SET!');
    }
    // console.log('RegulatoryAccepted' + this.context + modul.id);
    localStorage.setItem('RegulatoryAccepted' + this.context + modul.id, 'yes');
  }
  removeRegulatoryAccepted() {
    if (this.context === '') {
      // console.log('NO CONTEXT SET!');
    }

    for (var n = 1000; n <= 10000; n++) {
      localStorage.removeItem('RegulatoryAccepted' + this.context + n); //'6013');
    }

  }
  getRegulatoryAccepted(modul: Modul): string {
    if (this.context === '') {
      // console.log('NO CONTEXT SET!');
    }
    return localStorage.getItem('RegulatoryAccepted' + this.context + modul.id);
  }

  // Store the select as json-string, and restore it from the string
  storeAgendaModel(selection: Modul[]) {
    // console.log(JSON.stringify(selection))
    if (this.getModulemode() === '') {
      // console.log('NO CONTEXT SET!');
    }
    localStorage.setItem('agendaModel' + this.getModulemode(), JSON.stringify(selection));
  }

  getAgendaModel(): Modul[] {
    let temp = '';
    if (this.getModulemode() === '') {
      // console.log('NO CONTEXT SET!');
    }
    temp = localStorage.getItem('agendaModel' + this.getModulemode());
    // console.log ("tempArray: " + temp)
    if (temp != null && temp !== '') {
      return JSON.parse(temp);
    }
    return [];
  }


  // Store the select as json-string, and restore it from the string
  storeLastAgendaModel(selection: Modul[]) {
    // console.log("*** storeLastAgendaModel ***")
    // console.log(JSON.stringify(selection))

    var result: Modul[] = [];
    result = this.stripFromTree(selection);
    localStorage.setItem('LASTagendaModel', JSON.stringify(selection));

  }


  getLastAgendaModel(): Modul[] {
    let temp = '';
    temp = localStorage.getItem('LASTagendaModel');
    // console.log("tempArray: " + temp)
    if (temp != null && temp !== '') {
      return JSON.parse(temp);
    }
    return [];
  }



  storeIdListFromTree(selection: Modul[]) {

    let favString = '';
    /*for (const entry of selection) {
        favString += entry.id + ';';
    }*/
    for (const entry of this.module) {
      if (entry.is_checked) {
        favString += entry.id + ';';
      }
    }
    // console.log("Idliste " + favString);
    localStorage.setItem('idliste' + this.getModulemode(), favString);

  }


  storeSelection(selection: Modul[]) {
    if (this.modulemode == '') {
      // console.log('NO CONTEXT SET!')
    }
    // console.log(JSON.stringify(selection))
    localStorage.setItem('selection' + this.modulemode, JSON.stringify(selection))

    var favString = '';
    /*for (let entry of selection) {
        favString += entry.id + ';';
    }*/
    for (const entry of this.module) {
      if (entry.is_checked) {
        favString += entry.id + ';';
      }
    }
    localStorage.setItem('idliste' + this.modulemode, favString)

  }
  storeSelectionFlat(selection: string) {
    if (this.modulemode == '') {
      // console.log('NO CONTEXT SET!')
    }
    localStorage.setItem('selectionflat' + this.modulemode, selection)
  }

  getSelection(): Modul[] {

    let temp = '';
    if (this.modulemode === '') {
      // console.log('NO CONTEXT SET!')
    }
    temp = localStorage.getItem('selection' + this.modulemode);

    if (temp != null && temp !== '') {
      return JSON.parse(temp);
    }
    return [];
  }
  /*
  getSexxxlectionFlat(selection:Modul[]): string {

      var favString: string = ''

      if (this.context=='') {
          // console.log("NO CONTEXT SET!")
      }

      for (let entry of selection) {
          favString += entry.id + ';'
      }

      return favString
  }
  */

  storeIdList(idliste: string, modulemode: string) {
    localStorage.setItem('idliste' + modulemode, idliste);
  }
  getIdList(): string {
    if (this.modulemode === '') {
      // console.log('NO CONTEXT SET!');
    }

    // console.log('get list from storage>>');
    const idliste = localStorage.getItem('idliste' + this.modulemode);
    // console.log('idliste'+this.context + " " + idliste)
    return idliste;
  }

  /*
  storeAusgangslageCheckbox(showit: string) {
      if (this.context == '') {
          // console.log("NO CONTEXT SET!")
      }
      localStorage.setItem('showausgangslage' + this.context , showit)
  }
  getAusgangslageCheckbox(): string {
      if (this.context == '') {
          // console.log("NO CONTEXT SET!")
      }
      return localStorage.getItem('showausgangslage' + this.context)
  }
  storeAusgangslageText(atext: string) {
      if (this.context == '') {
          // console.log("NO CONTEXT SET!")
      }
      localStorage.setItem('ausgangslagetext' + this.context , atext)
  }
  getAusgangslageText(): string {
      if (this.context == '') {
          // console.log("NO CONTEXT SET!")
      }
      return localStorage.getItem('ausgangslagetext' + this.context)
  }*/


  storeKundendaten(showit: string) {
    if (this.context === '') {
      // console.log("NO CONTEXT SET!")
    }
    localStorage.setItem('Customerdata' + this.context, showit);
  }
  getKundendaten(): string {
    if (this.context === '') {
      // console.log("NO CONTEXT SET!")
    }
    return localStorage.getItem('Customerdata' + this.context);
  }


  storeCaller(showit: string) {
    if (this.context == '') {
      // console.log("NO CONTEXT SET!")
    }
    localStorage.setItem('Caller' + this.context, showit)
  }
  getCaller(): string {
    if (this.context == '') {
      // console.log("NO CONTEXT SET!")
    }
    return localStorage.getItem('Caller' + this.context)
  }

  storeCurrentPresentation(showit: string) {
    if (this.context == '') {
      // console.log("NO CONTEXT SET!")
    }
    localStorage.setItem('CurrentPresentation' + this.context, showit)
  }
  getCurrentPresentation(): string {
    if (this.context == '') {
      // console.log("NO CONTEXT SET!")
    }
    return localStorage.getItem('CurrentPresentation' + this.context)
  }


  getSubModuleById(id: Number, module: Modul[]): Modul {

    for (const entry of module) {

      if (entry.id == id) {
        return entry;
      }

      for (const sub1entry of entry.submodule) {

        if (sub1entry.id == id) {
          return sub1entry;
        }

        for (const sub2entry of sub1entry.submodule) {

          if (sub2entry.id == id) {
            return sub2entry;
          }

          for (const sub3entry of sub2entry.submodule) {
            if (sub3entry.id == id) {
              return sub3entry;
            }
          }
        }
      }
    }
    return null;
  }

  public getModuleById(id: Number, module: Modul[]): Modul {
    // console.log("getModuleById: " + id);
    for (const entry of module) {
      if (entry != null && entry.id === id) {
        return entry;
      }
    }
    return null;
  }

  public getModuleByIdDeep(id: Number, module: Modul[]): Modul {
    // console.log("getModuleById: " + id);
    for (const entry of module) {
      if (entry != null && entry.id === id) {
        // console.log("getModuleById1: ");
        // console.log(entry);
        return entry;
      }
      for (const subentry of entry.submodule) {
        if (subentry != null && subentry.id === id) {
          // console.log("getModuleById2: ");
          // console.log(subentry);
          return subentry;
        }
        for (const sub1entry of subentry.submodule) {
          if (sub1entry != null && sub1entry.id === id) {
            // console.log("getModuleById3: ");
            // console.log(sub1entry);
            return sub1entry;
          }
          for (const sub2entry of sub1entry.submodule) {
            if (sub2entry != null && sub2entry.id === id) {
              // console.log("getModuleById3: ");
              // console.log(sub1entry);
              return sub2entry;
            }
          }
        }
      }
    }
    return null;
  }


  isInModules(m: Modul, tA: Number[]): boolean {
    for (let entry of tA) {
      if (entry === m.id) {
        // console.log('>>>' + m.id + ' ' + entry + " yes");
        return true;
      }
    }
    // console.log('>>>' + m.id + 'no');
    return false;
  }


  isModuleInModules(m: Modul, tA: Modul[]): boolean {
    for (let entry of tA) {
      // console.log(m.id + " " + entry)
      if (entry.id === m.id) {
        return true;
      }
    }
    return false;
  }

  openFile(modul: Modul) {
    // console.log(">>>>" + this.context);
    // console.log(modul);
    const href = this.generatePPTLocation(modul, this.context);
    // console.log(href)
    window.open(href, '_blank');
  }


  // duplicate in metadata
  generatePPTLocation(entry: any, modulmode: string) {

    // console.log('>' + entry);
    let path = '';
    if (entry.path !== undefined) {
      path = entry.path;
      // console.log('>>' + path);
    }

    // console.log("modulmode>>:" + modulmode)
    if (modulmode === 'wm' || modulmode === 'pb') {
      // console.log("path: " + path)
      return this.wm_base + path + entry.link;
    }
    if (modulmode === 'uk') {
      return this.uk_base + path + entry.link;
    }
    if (modulmode === 'ik') {
      return this.ik_base + path + entry.link;
    }
    if (modulmode === 'english_wm') {
      return this.wm_base + path + entry.link;
    }
  }


  removeStringFromStringarray(key: string, myarray: string[]): string[] {

    let index: number = myarray.indexOf(key);

    if (index !== -1) {
      myarray.splice(index, 1);
    }
    return myarray
  }

  removeModuleFromModulelist(modul: Modul, alleModule: Modul[]): Modul[] {

    let index: number = alleModule.indexOf(modul);

    if (index !== -1) {
      alleModule.splice(index, 1);
    }
    return alleModule
  }

  removeModuleFromModuleTree(modul: Modul, alleModule: Modul[]): Modul[] {

    let index: number = alleModule.indexOf(modul);
    if (index !== -1) {
      alleModule.splice(index, 1);
      return alleModule
    }

    for (let m of alleModule) {
      let index: number = m.submodule.indexOf(modul);
      if (index !== -1) {
        m.submodule.splice(index, 1);
        return alleModule
      }
      for (let sm of m.submodule) {
        let index: number = sm.submodule.indexOf(modul);
        if (index !== -1) {
          sm.submodule.splice(index, 1);
          return alleModule
        }
      }
    }

  }

  goback() {
    localStorage.setItem('reload', 'yes');
  }


  browserwarnung(): boolean {
    return this.detectBrowser()
  }

  detectBrowser(): boolean {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    // console.log(ua)

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return false;
    }

    var edge = ua.indexOf('Firefox/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false; // parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    var edge = ua.indexOf('Chrome/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false; // parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return false; // parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    let browser = ua.indexOf('Safari');
    if (browser > 0) {
      // IE 10 or older => return version number
      return true;
    }

    // other browser
    return false;
  }

  sortModulearray(modularray: Modul[]) {

    modularray.sort((a: any, b: any) => {
      let one = a.id;
      let two = b.id;

      if (a.id < 99999) {
        one = a.id / 1000;
      } else if (a.id < 99999999) {
        one = a.id / 1000000;
      } else if (a.id < 99999999999) {
        one = a.id / 1000000000;
      }

      if (b.id < 99999) {
        two = b.id / 1000;
      } else if (b.id < 99999999) {
        two = b.id / 1000000;
      } else if (b.id < 99999999999) {
        two = b.id / 1000000000;
      }

      // console.log (one + ' ' + two);
      if (one < two) {
        return -1;
      } else if (one > two) {
        return 1;
      } else {
        return 0;
      }
    });
    return modularray
  }

  dumpSelection(module: Modul[]) {
    // console.log('------Modul[]------');
    for (let entry of module) {
      // console.log(entry.id);
      for (let sub1entry of entry.submodule) {
        // console.log(sub1entry.id);
        for (const sub2entry of sub1entry.submodule) {
          // console.log(sub2entry.id);
          for (const sub3entry of sub2entry.submodule) {
            // console.log(sub3entry.id);
          }
        }
      }
    }
    // console.log('---------------------------');
  }

  fullDumpSelection(module: Modul[]) {
    // console.log('------Full Modul[]------');
    for (let entry of module) {
      // console.log(entry);
      for (let sub1entry of entry.submodule) {
        // console.log(sub1entry);
        for (const sub2entry of sub1entry.submodule) {
          // console.log(sub2entry);
          for (const sub3entry of sub2entry.submodule) {
            // console.log(sub3entry);
          }
        }
      }
    }
    // console.log('---------------------------');
  }

  stripFromTree(module: Modul[]) {
    var result: Modul[] = [];
    for (let entry of module) {
      for (let sub1entry of entry.submodule) {
        if (sub1entry.submodule.length === 0) {
          sub1entry.level = 1;
          result.push(sub1entry);
        }
        for (const sub2entry of sub1entry.submodule) {
          if (sub2entry.submodule.length === 0) {
            sub2entry.level = 2;
            result.push(sub2entry);
          }
          for (const sub3entry of sub2entry.submodule) {
            // console.log(sub3entry.id);
          }
        }
      }
    }
    // console.log('---------------------------');
    this.fullDumpSelection(result);
    return result;
  }

  wm_assets: Modul[] = [];
  wm_en_assets: Thema[] = [];

  uk_assets: Modul[] = [];
  uk_themen_assets: Thema[] = [];
  uk_themen_en_assets: Thema[] = [];

  ik_assets: Modul[] = [];
  ik_en_assets: Modul[] = [];

  pb_de_assets: Modul[] = [];

  english_assets: Modul[] = [];

  wm_themen_assets: Thema[] = []

  load(assetName) {

    // console.log("*** COMMASTER ***");
    // console.log("Load: " + assetName);

    return new Promise((resolve) => {
      if (assetName === 'wm_module') {
        // console.log("load module.txt")
        this.wm_assets = this.httpGet('./assets/data/module.txt');
      }
      if (assetName === 'wm_module_en') {
        // console.log("load module_en.txt")
        this.wm_assets = this.httpGet('./assets/data/module_en.txt');
      }
      if (assetName === 'wm_en_module') {
        this.wm_en_assets = this.httpGetThemen('./assets/data/fertigethemen_en.txt');
      }
      if (assetName === 'wm_themen') {
        this.wm_themen_assets = this.httpGetThemen('./assets/data/fertigethemen.txt');
      }

      if (assetName === 'uk_module') {
        this.uk_assets = this.httpGet('./assets/data/module_uk.txt');
      }
      if (assetName === 'uk_themen') {
        this.uk_themen_assets = this.httpGetThemen('./assets/data/fertigethemen_uk.txt');
      }
      if (assetName === 'uk_themen_en') {
        this.uk_themen_en_assets = this.httpGetThemen('./assets/data/fertigethemen_uk_en.txt');
      }

      if (assetName === 'ik_module') {
        this.ik_assets = this.httpGet('./assets/data/module_ik.txt');
      }
      if (assetName === 'ik_en_module' || assetName === 'ik_module_en') {
        this.ik_en_assets = this.httpGet('./assets/data/module_ik_en.txt');
      }
      if (assetName === 'pb_module') {
        console.log("load PB")
        this.pb_de_assets = this.httpGet('./assets/data/module_pb.txt');
      }

      if (assetName === 'english_module') {
        // console.log("load english");
        this.english_assets = this.httpGet('./assets/data/module_english.txt');
      }
      resolve(true);
    });
  }

  loadGeneric(assetName) {

    return new Promise((resolve) => {
      // console.log("load module.txt")
      this.module = this.httpGet('./assets/data/' + assetName + '.txt');
    });
  }

  httpGetHTML(theUrl): string {
    try {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.open('GET', theUrl, false);
      xmlHttp.onload = function () {
        if (xmlHttp.status === 200 || xmlHttp.status === 0) {
          return xmlHttp.response;
        } else {
          // console.log("Exception caught or not found")
        }
      }
      xmlHttp.send(null);

    } catch (exc) {
      // console.log("Exception caught: " + exc.message)
      return ""
    }
  }

  httpGet(theUrl): Modul[] {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', theUrl, false);
    xmlHttp.send(null);
    // console.log(JSON.parse(xmlHttp.responseText));

    return JSON.parse(xmlHttp.responseText);
  }

  httpGetThemen(theUrl): Thema[] {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', theUrl, false);
    xmlHttp.send(null);
    return JSON.parse(xmlHttp.responseText);
  }
}
